<template>
  <div>
    <span class="mb-1 block text-zinc-400 dark:text-gray-400">
      {{ props.label }}

      <!-- TODO fix in modal -->
      <v-tooltip
        v-if="props.hint"
        :content="props.hint"
        class="inline-block"
      >
        <Icon
          class="-mt-[3px] text-xl outline-none"
          name="tabler:help-circle-filled"
        />
      </v-tooltip>
    </span>
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  label: string
  hint?: string
}>()
</script>
